
<af-page-bar title="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.tasks' | translate }}">
</af-page-bar>

<af-page-filter [loading]="isLoading" [params]="queryParams" (filter)="loadData()" (reset)="onReset()" (clear)="onClear()">
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.keyword' | translate }}</label>
        <input type="text" class="form-control" [(ngModel)]="queryParams.searchTerm" />
        <af-help helpid="project.list.filter.searchTerm"></af-help>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.account center' | translate }}</label>
        <af-multiselect [options]="accountCenterMultiselect"
                        displayProp="name"
                        name="selectAC"
                        [selectionLimit]="0"
                        [showSelectAll]="true"
                        [showUnselectAll]="true"
                        [showSearch]="true"
                        [(selectedOptions)]="selectedAccountcenters">
        </af-multiselect>
        <af-help helpid="project.list.filter.accountCenterCode"></af-help>
    </div>
    <div class="form-group ac-group">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.technician' | translate }}</label>
        <div class="input-group">
            <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <input type="text" class="form-control" [(ngModel)]="selectedTechnician"
                   [editable]="false"
                   [ngbTypeahead]="employeeAutocomplete"
                   [resultFormatter]="employeeFormatter"
                   [inputFormatter]="employeeFormatter" />
        </div>
        <af-help helpid="project.list.filter.employee"></af-help>
    </div>
    <div class="form-group ac-group">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.company' | translate }}</label>
        <div class="input-group">
            <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <input type="text" name="CompanyId" class="form-control"
                   [(ngModel)]="selectedCompany"
                   [editable]="false"
                   [ngbTypeahead]="companyAutocomplete"
                   [resultFormatter]="companyFormatter"
                   [inputFormatter]="companyFormatter" />
        </div>
    </div>
    <div class="form-group ac-group">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.workplace' | translate }}</label>
        <div class="input-group">
            <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <input type="text" name="WorkPlaceId" class="form-control"
                   [(ngModel)]="selectedWorkPlace"
                   [editable]="false"
                   [ngbTypeahead]="workPlaceAutocomplete"
                   [resultFormatter]="workPlaceFormatter"
                   [inputFormatter]="workPlaceFormatter" />
        </div>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.project task status' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.isArchived">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.all' | translate }}</option>
            <option [ngValue]="false">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.active' | translate }}</option>
            <option [ngValue]="true">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.archived' | translate }}</option>
        </select>
        <af-help helpid="project.list.filter.isArchived"></af-help>
    </div>
    <div class="filter-group">
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.from' | translate }}</label>
            <af-datepicker [(ngModel)]="queryParams.dateFrom" name="DateFrom"></af-datepicker>
        </div>
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.to' | translate }}</label>
            <af-datepicker [(ngModel)]="queryParams.dateTo" name="DateTo"></af-datepicker>
        </div>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.the activity affects the critical characteristic of the part' | translate }}</label>
        <select class="form-control form-select"
                [(ngModel)]="queryParams.hasCriticalCharacteristicOfPart">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.all' | translate }}</option>
            <option [ngValue]="true">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.yes' | translate }}</option>
            <option [ngValue]="false">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.no' | translate }}</option>
        </select>
        <af-help helpid="project.list.filter.hasCriticalCharacteristicOfPart"></af-help>
    </div>
    <div class="filter-group">
        <div class="form-group">
            <af-checkbox [(ngModel)]="queryParams.papersNotCompleted" label="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.papers not completed' | translate }}"></af-checkbox>
            <af-help helpid="project.list.filter.papersNotCompleted"></af-help>
        </div>
        <div class="form-group">
            <af-checkbox [(ngModel)]="queryParams.payrollNotFinished" label="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.payroll not finished' | translate }}"></af-checkbox>
            <af-help helpid="project.list.filter.payrollNotFinished"></af-help>
        </div>
        <div class="form-group">
            <af-checkbox [(ngModel)]="queryParams.orderNotReceived" label="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.order not received' | translate }}"></af-checkbox>
            <af-help helpid="project.list.filter.orderNotReceived"></af-help>
        </div>
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.metriq' | translate }}</label>
            <select class="form-control form-select"
                    [(ngModel)]="queryParams.isMetriq">
                <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.all' | translate }}</option>
                <option [ngValue]="1">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.view' | translate }}</option>
                <option [ngValue]="2">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.view _ input' | translate }}</option>
                <option [ngValue]="0">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.no' | translate }}</option>

            </select>
            <af-help helpid="projectTask.list.filter.isMetriq"></af-help>
        </div>
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.overhead' | translate }}</label>
            <select class="form-control form-select"
                    [(ngModel)]="queryParams.isOverHead">
                <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.all' | translate }}</option>
                <option [ngValue]="true">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.yes' | translate }}</option>
                <option [ngValue]="false">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.no' | translate }}</option>
            </select>
            <af-help helpid="project.list.filter.isOverHead"></af-help>
        </div>
    </div>
</af-page-filter>

<af-table #afTable [value]="model" [loading]="isLoading" [params]="queryParams" [sortBy]="sortBy" (load)="loadData()">
    <af-column field="projectTaskNumber" sortable="projectTaskNumber" header="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.number' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/operation/projectTask', item.projectTaskId]">{{item.fullProjectTaskNumber}}</a>
        </ng-template>
    </af-column>
    <af-column field="title" sortable="title" header="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.title' | translate }}"></af-column>
    <af-column field="companyTitle" sortable="companyTitle" header="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.company' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/crm/company', item.companyId]">{{ item.companyTitle }}</a>
        </ng-template>
    </af-column>
    <af-column field="technicianName" sortable="technicianName" header="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.technician' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/operation/employee', item.technicianId]">{{ item.technicianName }}</a>
        </ng-template>
    </af-column>
    <af-column field="companyTitle" sortable="companyTitle" header="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.workplace' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/organization/workPlace', item.workPlaceId]">{{ item.workPlaceTitle }}</a>
        </ng-template>
    </af-column>
    <af-column field="papersCompletitionUtc" sortable="papersCompletitionUtc" header="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.papers completed' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" [checked]="item.papersCompletitionUtc" disabled>
                <label class="form-check-label"></label>
            </div>
        </ng-template>
    </af-column>
    <af-column field="payrollFinishedDate" sortable="payrollFinishedDate" header="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.papers finished' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" [checked]="item.payrollFinishedDate" disabled>
                <label class="form-check-label"></label>
            </div>
        </ng-template>
    </af-column>
    <af-column field="orderReceivedDate" sortable="orderReceivedDate" header="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.order received date' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.orderReceivedDate | afDate: 'L'}}
        </ng-template>
    </af-column>
    <af-column field="invoiceIssuedDate" sortable="invoiceIssuedDate" header="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-list.component.invoiced' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.invoiceIssuedDate | afDate: 'L'}}
        </ng-template>
    </af-column>
</af-table>
