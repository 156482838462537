import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class WorkPlaceService
    extends HttpService<app.organization.WorkPlaceListViewModel, app.organization.WorkPlaceFormViewModel, app.organization.WorkPlaceInsertUpdateViewModel>
{
    objectKey = "org.WorkPlace";

    constructor(http: HttpClient) {
        super(http, "api/organization/workPlace/");
    }

    getSelectList(page?: number, pageSize?: number, searchTerm?: string,
        accountCenterCodes?: string, branchId?: number, countryCode?: string, hollenCompanyCode?:string): Observable<app.organization.WorkPlaceSelectListViewModel[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }
        if (accountCenterCodes) {
            params = params.set("accountCenterCodes", accountCenterCodes);
        }
        if (branchId) {
            params = params.set("branchId", branchId.toString());
        }
        if (hollenCompanyCode) {
            params = params.set("hollenCompanyCode", hollenCompanyCode);
        }
        if (countryCode) {
            params = params.set("countryCode", countryCode);
        }
        return this.http.get<app.organization.WorkPlaceSelectListViewModel[]>(this.url + "list", { params });
    }
}
